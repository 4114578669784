import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean, showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AppBreadcrumb } from '../../AppBreadcrumb';
import QRCode from "qrcode.react";


function ViewObject(props) {



    let showDetail = []
    for (const prope of props.properties) {
        let value = "";
        if (prope.key.includes(".")) {
            let str = prope.key.split(".")
            if (props.data[str[0]] !== undefined && (props.data[str[0]] !== null))
                value = props.data[str[0]][str[1]];
        } else {
            value = props.data[prope.key]
        }

        showDetail.push(<div className="p-grid" ><div className="p-col-12" style={{ display: "inherit" }}> <div className="p-col-5">{prope.name} </div>&nbsp;&nbsp;&nbsp;&nbsp;<div className="p-col-5">{value}</div></div><br /></div>)

    }

    const cancel = () => {
        props.history.goBack();
    }

    const downloadQR = () => {
        const canvas = document.getElementById("qr_code");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = props?.data?.id + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (

        <>
            {console.log("staticQrLink is ==", props, props?.data, props?.data?.staticQrLink)}

            {showDetail.map(item => {
                return item;
            })}

            {props?.data?.staticQrLink && props?.data?.staticQrLink !== null &&
                <>
                    <div className="card card-w-title">

                        <div style={{ textAlign: "center" }}>
                            <QRCode
                                id="qr_code"
                                value={props?.data?.staticQrLink}
                                size={250}
                                level={"H"}
                                includeMargin={true}
                            />
                            <div className="imgButton">
                                <Button icon="pi-md-file-download" className="p-button-secondary" onClick={() => downloadQR()} />
                            </div>
                        </div>

                    </div>
                </>
            }

            {props.plantProducts ? props.plantProducts.length > 0 ?

                <>
                    <div className="p-grid">
                        <div className="p-col-8">

                            <br />
                            <h2>Products</h2>
                            <br />
                            <DataTable responsive={true} columnResizeMode="fit" resizableColumns={true} value={props.plantProducts} className="p-datatable-striped">
                                <Column field="product.productCode" header="Code"></Column>
                                <Column field="noOfLabelPerRoll" header="No. Of Label Per Roll"></Column>

                            </DataTable>
                            <br />


                        </div>
                    </div>

                </>
                : null : null

            }


            <div className="p-col-8">

            </div></>)


}
export default withRouter(ViewObject);
