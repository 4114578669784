import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { HttpService } from "../../service/HttpService";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import rule from "./../../constant/Rule";
import { AppBreadcrumb } from "../../AppBreadcrumb";
import { FileUpload } from "primereact/fileupload";
import colors from "./../../constant/colors";
import { ColorPicker } from "primereact/colorpicker";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { PriviladgeContext } from "../../context/priviladge-context";

var _ = require("lodash");
function ProductAdd(props) {
  const priviladgeContext = useContext(PriviladgeContext);
  var [disableFile, setDisableFile] = useState(false);
  var [disableSave, setDisableSave] = useState(false);
  var [obj, setObj] = useState({
    color: "#000000",
    authenticationMsg: "Does this information match with your sample",
    customTextPostScan:
      "Please enter your mobile no to authenticate the product",
  });
  var [disableContentFile, setDisableContentFile] = useState(false);
  var [clients, setClients] = useState([]);
  var [fontType, setFontType] = useState([]);

  const growl = useRef(null);
  const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });
  const httpService = new HttpService();

  useEffect(() => {
    getFontList();
    getCustomer();
  }, []);

  const getFontList = () => {
    trackPromise(
      httpService
        .getApi("fontList")
        .then((response) => {
          setFontType(response.data);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  const getCustomer = () => {
    trackPromise(
      httpService
        .getApi("customers/company?type=Customer")
        .then((response) => {
          setClients(response.data.content);
          // this.setState({ dataTableValue: response.data, totalRecords: response.data.totalElements });
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  const handleChange = (e, type, name) => {
    let val = e.target.value;
    if (type === "date") {
      val = moment(new Date(e.target.value))
        .format("YYYY-MM-DDTHH:mm:ss")
        .toString();
      if (name == "mfgDate") {
        if (obj.expiryDate != undefined && val >= obj.expiryDate) {
          showMessage(
            "error",
            "Error Message",
            "Expiry Month can not be less than or equal to Mfg Month",
            growl
          );
          setDisableSave(true);
        } else {
          setDisableSave(false);
          obj.mfgDate = val;
        }
      } else {
        if (obj.mfgDate != undefined && val <= obj.mfgDate) {
          showMessage(
            "error",
            "Error Message",
            "Expiry Month can not be less than or equal to Mfg Month",
            growl
          );
          setDisableSave(true);
        } else {
          setDisableSave(false);
          obj.expiryDate = val;
        }
      }
    }
    setObj({ ...obj, [e.target.name]: val });
    if (type === "date") return e.target.value;
    else return val;
  };

  const cancel = () => {
    props.history.push("product_list");
  };

  const onFileSelect = (e, name) => {
    let fileArray = [];
    if (obj.files !== undefined && obj.files !== "") {
      fileArray = [...obj.files];
    }

    var file = e.files[0];
    const fileName = e.files[0].name;
    const lastDot = fileName.lastIndexOf(".");

    const ext = fileName.substring(lastDot + 1);
    file.originalFilename = name + "." + ext;
    fileArray.push(file);
    setObj({ ...obj, files: fileArray });
  };

  const onRemoveFile = (e) => {
    if (obj.files !== undefined && obj.files.length > 0) {
      let index = obj.files.findIndex((x) => x.name === e.file.name);
      if (index > -1) {
        obj.files.splice(index, 1);
      }
    }
  };

  const save = (data) => {
    if (
      obj.productCode == undefined ||
      obj.productCode == null ||
      obj.productCode == ""
    ) {
      showMessage("error", "Error Message", "Product Code is required", growl);
      return false;
    }
    obj = clean(obj);

    if (priviladgeContext.isAdmin) obj["customer.id"] = obj.customer.id;
    console.log(obj);
    let formData = new FormData();

    for (const property in obj) {
      if (property === "files") {
        if (obj[property] !== undefined)
          obj[property].forEach((element) => {
            formData.append("file", element, element.originalFilename);
          });
      } else if (obj[property] !== undefined)
        formData.append(property, obj[property]);
    }

    trackPromise(
      httpService
        .getApiMultipart("products", formData)
        .then((response) => {
          cancel();
        })
        .catch((error) => {
          if (checkForError(error)) {
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
          }
        })
    );
  };

  const setColors = (e) => {
    if (e.target.name == "color") {
      if (e.target.value.name == undefined) {
        validateColor(e.target.value);
      } else {
        validateColor(e.target.value.name);
      }
    }
  };

  const validateColor = (color1) => {
    if (color1 == "" || color1 == undefined) {
      return;
    }
    color1 = color1?.toLowerCase();
    let c = window.w3color(color1);
    if (c.valid) {
      setObj({ ...obj, color: c.toHexString() });
    }
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          { label: "Product List", url: "#product_list" },
          { label: "Product Add" },
        ]}
      />

      <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-8 ">
          <div className="card card-w-title">
            <h1>Add Product</h1>
            <div className="p-grid p-fluid form-group">
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.productName ? "p-error" : ""}
                      name="productName"
                      value={obj.productName}
                      rules={{
                        ...rule.RULE.requiredRule,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Product Name</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.productName?.message}
                </span>
              </div>

              {/* <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.staticQr}
                      name={"staticQr"}
                      inputId={obj.staticQr}
                      ariaLabelledBy={obj.staticQr}
                      value={obj.staticQr}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.staticQr}
                      className="p-checkbox-label merginLeft15"
                    >
                     Authentication Via Qrcode
                    </label>
                  </div>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.productSrNo?.message}
                </span>
              </div> */}

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.productSrNo ? "p-error" : ""}
                      name="productSrNo"
                      value={obj.productSrNo}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal25,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Product Sr No.</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.productSrNo?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.productCode ? "p-error" : ""}
                      name="productCode"
                      value={obj.productCode}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal25,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Product Code</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.productCode?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.productDescription ? "p-error" : ""}
                      name="productDescription"
                      value={obj.productDescription}
                      rules={{
                        ...rule.RULE.requiredRule,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Product Description</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.productDescription?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.labelSize ? "p-error" : ""}
                      name="labelSize"
                      value={obj.labelSize}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Label Size</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.labelSize?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.labelSpecification ? "p-error" : ""}
                      name="labelSpecification"
                      value={obj.labelSpecification}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal250,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Label Specification</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.labelSpecification?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i class="fab fa-product-hunt fa-lg"></i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.productLink ? "p-error" : ""}
                      name="productLink"
                      value={obj.productLink}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Product Link</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.productLink?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i class="fab fa-product-hunt fa-lg"></i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.offerCode ? "p-error" : ""}
                      name="offerCode"
                      value={obj.offerCode}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Offer Code</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.offerCode?.message}
                </span>
              </div>

              {
                // priviladgeContext.isAdmin && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">perm_identity</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        optionLabel="companyName"
                        optionValue="id"
                        filter
                        filterBy="companyName"
                        className={errors.client ? "p-error" : ""}
                        rules={{ ...rule.RULE.requiredRule }}
                        as={Dropdown}
                        control={control}
                        options={clients}
                        placeholder={"Customer"}
                        name="customer"
                        value={obj.customer}
                        onChange={(e) => handleChange(e[0])}
                      />
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.customer?.message}
                  </span>
                </div>
                // )
              }
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">message</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.authenticationMsg ? "p-error" : ""}
                      name="authenticationMsg"
                      defaultValue={obj.authenticationMsg}
                      value={obj.authenticationMsg}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal250,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Customize Message</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.authenticationMsg?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">perm_identity</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      placeholder={"Font Name"}
                      filter
                      className={errors.fontName ? "p-error" : ""}
                      as={Dropdown}
                      control={control}
                      options={fontType}
                      name="fontName"
                      value={obj.fontName}
                      onChange={(e) => handleChange(e[0])}
                    />
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.fontName?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.fontSize ? "p-error" : ""}
                      name="fontSize"
                      value={obj.fontSize}
                      rules={{
                        ...rule.RULE.maxLengthRule7,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      type="number"
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Font Size</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.fontSize?.message}
                </span>
              </div>

              <div className="p-col-12  ">
                <label>Font Color</label>
              </div>

              <div className="p-col-12 displayFlex">
                <div className="p-inputgroup p-col-5">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">palette</i>
                  </span>
                  <span className="md-inputfield">
                    <Dropdown
                      style={{ width: "100%" }}
                      optionLabel={"name"}
                      filter
                      filterBy="name"
                      optionValue={"name"}
                      editable
                      options={colors}
                      className={errors.column ? "p-error" : ""}
                      name="color"
                      value={obj.color}
                      as={Dropdown}
                      control={control}
                      onChange={(e) => setColors(e)}
                    />
                    {/* <Controller placeholder={"Font Name"}  filter className={errors.fontName ? "p-error" : ''}  as={Dropdown} control={control} options={fontType} name="fontName" value={obj.fontName} onChange={(e) => handleChange(e[0])} /> */}
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.color?.message}
                </span>

                <div className="p-col-3">
                  <ColorPicker
                    format="hex"
                    name="color"
                    value={obj.color}
                    onChange={(e) => setColors(e)}
                  />
                </div>
              </div>

              {obj.showMfgDate && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">insert_invitation</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        className={errors.mfgDate ? "p-error" : ""}
                        name="mfgDate"
                        value={obj.mfgDate}
                        rules={{ ...rule.RULE.minRuleVal1 }}
                        as={Calendar}
                        control={control}
                        onChange={(e) => handleChange(e[0], "date", "mfgDate")}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="2010:2050"
                        dateFormat="M yy"
                        view="month"
                      />
                      <label>Mfg Month</label>
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.mfgDate?.message}
                  </span>
                </div>
              )}

              {obj.showExpiryDate && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">insert_invitation</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        className={errors.expiryDate ? "p-error" : ""}
                        name="expiryDate"
                        value={obj.expiryDate}
                        rules={{ ...rule.RULE.minRuleVal1 }}
                        as={Calendar}
                        control={control}
                        onChange={(e) =>
                          handleChange(e[0], "date", "expiryDate")
                        }
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="2010:2050"
                        dateFormat="M yy"
                        view="month"
                      />
                      <label>Expiry Month</label>
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.expiryDate?.message}
                  </span>
                </div>
              )}
              {obj.authenticationActivated && (
                <>
                  {obj.captureUserData && (
                    <>
                      <div className="p-col-8">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="material-icons">message</i>
                          </span>
                          <span className="md-inputfield">
                            <Controller
                              className={
                                errors.countOfScanning ? "p-error" : ""
                              }
                              name="countOfScanning"
                              type="number"
                              value={obj.countOfScanning}
                              rules={{
                                ...rule.RULE.requiredRule,
                                ...rule.RULE.maxLengthRuleVal250,
                              }}
                              as={InputText}
                              control={control}
                              onChange={(e) => handleChange(e[0])}
                            />
                            <label>Allowed No. of Scans for Registration</label>
                          </span>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.countOfScanning?.message}
                        </span>
                      </div>

                      <div className="p-col-8">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="material-icons">message</i>
                          </span>
                          <span className="md-inputfield">
                            <Controller
                              className={
                                errors.customTextPostScan ? "p-error" : ""
                              }
                              name="customTextPostScan"
                              defaultValue={obj.customTextPostScan}
                              value={obj.customTextPostScan}
                              rules={{
                                ...rule.RULE.requiredRule,
                                ...rule.RULE.maxLengthRuleVal250,
                              }}
                              as={InputText}
                              control={control}
                              onChange={(e) => handleChange(e[0])}
                            />
                            <label>Custom text on post scan</label>
                          </span>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.customTextPostScan?.message}
                        </span>
                      </div>

                      <div className="p-col-8">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="material-icons">message</i>
                          </span>
                          <span className="md-inputfield">
                            <Controller
                              className={
                                errors.customTextOnIdentification
                                  ? "p-error"
                                  : ""
                              }
                              name="customTextOnIdentification"
                              defaultValue={obj.customTextOnIdentification}
                              value={obj.customTextOnIdentification}
                              rules={{
                                ...rule.RULE.requiredRule,
                                ...rule.RULE.maxLengthRuleVal250,
                              }}
                              as={InputText}
                              control={control}
                              onChange={(e) => handleChange(e[0])}
                            />
                            <label>Custom Text on Identification</label>
                          </span>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.customTextOnIdentification?.message}
                        </span>
                      </div>

                      {obj.customer != undefined &&
                        obj.customer.advanceWorkflow && (
                          <div className="p-col-8">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                <i className="material-icons">text_fields</i>
                              </span>
                              <span className="md-inputfield">
                                <Controller
                                  className={
                                    errors.customTextForRegistration
                                      ? "p-error"
                                      : ""
                                  }
                                  name="customTextForRegistration"
                                  value={obj.customTextForRegistration}
                                  rules={{
                                    ...rule.RULE.requiredRule,
                                    ...rule.RULE.maxLengthRuleVal250,
                                  }}
                                  as={InputText}
                                  control={control}
                                  onChange={(e) => handleChange(e[0])}
                                />
                                <label>Label for Registration</label>
                              </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                              {errors.customTextForRegistration?.message}
                            </span>
                          </div>
                        )}

                      <div className="p-col-8">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="material-icons">message</i>
                          </span>
                          <span className="md-inputfield">
                            <Controller
                              className={
                                errors.customTextOnLastPage ? "p-error" : ""
                              }
                              name="customTextOnLastPage"
                              defaultValue={obj.customTextOnLastPage}
                              value={obj.customTextOnLastPage}
                              rules={{
                                ...rule.RULE.requiredRule,
                                ...rule.RULE.maxLengthRuleVal250,
                              }}
                              as={InputText}
                              control={control}
                              onChange={(e) => handleChange(e[0])}
                            />
                            <label>Custom Text on Last Page</label>
                          </span>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.customTextOnLastPage?.message}
                        </span>
                      </div>

                      <div className="p-col-8">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            <i className="material-icons">message</i>
                          </span>
                          <span className="md-inputfield">
                            <Controller
                              className={
                                errors.videoLinkOnLastPage ? "p-error" : ""
                              }
                              name="videoLinkOnLastPage"
                              defaultValue={obj.videoLinkOnLastPage}
                              value={obj.videoLinkOnLastPage}
                              rules={{
                                ...rule.RULE.requiredRule,
                                ...rule.RULE.maxLengthRuleVal250,
                              }}
                              as={InputText}
                              control={control}
                              onChange={(e) => handleChange(e[0])}
                            />
                            <label>Video Link on Last Page</label>
                          </span>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.videoLinkOnLastPage?.message}
                        </span>
                      </div>
                    </>
                  )}
                </>
              )}

              {obj.enableLoyaltyPoints && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">message</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        className={errors.loyaltyPoints ? "p-error" : ""}
                        name="loyaltyPoints"
                        type="number"
                        defaultValue={obj.loyaltyPoints}
                        value={obj.loyaltyPoints}
                        rules={{
                          ...rule.RULE.requiredRule,
                          ...rule.RULE.maxLengthRuleVal250,
                        }}
                        as={InputText}
                        control={control}
                        onChange={(e) => handleChange(e[0])}
                      />
                      <label>loyaltyPoints</label>
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.loyaltyPoints?.message}
                  </span>
                </div>
              )}
              {obj.showOffers && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">message</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        className={errors.offersPageLink ? "p-error" : ""}
                        name="offersPageLink"
                        defaultValue={obj.offersPageLink}
                        value={obj.offersPageLink}
                        rules={{
                          ...rule.RULE.requiredRule,
                          ...rule.RULE.maxLengthRuleVal250,
                        }}
                        as={InputText}
                        control={control}
                        onChange={(e) => handleChange(e[0])}
                      />
                      <label>Offers Page Link</label>
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.offersPageLink?.message}
                  </span>
                </div>
              )}

              {obj.checkCountOfScan && (
                <>
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">message</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.allowNumberOfScans ? "p-error" : ""}
                          name="allowNumberOfScans"
                          type="number"
                          value={obj.allowNumberOfScans}
                          rules={{
                            ...rule.RULE.requiredRule,
                            ...rule.RULE.maxLengthRuleVal250,
                          }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Allowed count of scans</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.allowNumberOfScans?.message}
                    </span>
                  </div>

                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">message</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={
                            errors.msgAfterScanExceded ? "p-error" : ""
                          }
                          name="msgAfterScanExceded"
                          type="text"
                          value={obj.msgAfterScanExceded}
                          rules={{ ...rule.RULE.requiredRule }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Message when scan exceeded </label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.msgAfterScanExceded?.message}
                    </span>
                  </div>
                </>
              )}

              {obj.enableWarranty && (
                <>
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">message</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.warrantyLink ? "p-error" : ""}
                          name="warrantyLink"
                          type="text"
                          value={obj.warrantyLink}
                          rules={{ ...rule.RULE.requiredRule }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Enable Warranty </label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.warrantyLink?.message}
                    </span>
                  </div>
                </>
              )}

              <div className="p-grid p-col-10">
                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.authenticationActivated}
                      name={"authenticationActivated"}
                      inputId={obj.authenticationActivated}
                      ariaLabelledBy={obj.authenticationActivated}
                      value={obj.authenticationActivated}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.authenticationActivated}
                      className="p-checkbox-label merginLeft15"
                    >
                      Active Authentication
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.authenticationActivated?.message}
                  </span>
                </div>

                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.captureUserData}
                      name={"captureUserData"}
                      inputId={obj.captureUserData}
                      ariaLabelledBy={obj.captureUserData}
                      value={obj.captureUserData}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.captureUserData}
                      className="p-checkbox-label merginLeft15"
                    >
                      Capture User Data
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.captureUserData?.message}
                  </span>
                </div>
              </div>

              <div className="p-grid p-col-10">
                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.showProductImage}
                      name={"showProductImage"}
                      inputId={obj.showProductImage}
                      ariaLabelledBy={obj.showProductImage}
                      value={obj.showProductImage}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.showProductImage}
                      className="p-checkbox-label merginLeft15"
                    >
                      Show Label Image On Scanning
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.showProductImage?.message}
                  </span>
                </div>

                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.userInvoiceUpload}
                      name={"userInvoiceUpload"}
                      inputId={obj.userInvoiceUpload}
                      ariaLabelledBy={obj.userInvoiceUpload}
                      value={obj.userInvoiceUpload}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.userInvoiceUpload}
                      className="p-checkbox-label merginLeft15"
                    >
                      User Invoice Upload
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.userInvoiceUpload?.message}
                  </span>
                </div>
                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.isColor}
                      name={"isColor"}
                      inputId={obj.isColor}
                      defaultValue={true}
                      ariaLabelledBy={obj.isColor}
                      value={obj.isColor}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.isColor}
                      className="p-checkbox-label merginLeft15"
                    >
                      Color QR Code
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.isColor?.message}
                  </span>
                </div>
                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.checkCountOfScan}
                      name={"checkCountOfScan"}
                      inputId={obj.checkCountOfScan}
                      defaultValue={true}
                      ariaLabelledBy={obj.checkCountOfScan}
                      value={obj.checkCountOfScan}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.checkCountOfScan}
                      className="p-checkbox-label merginLeft15"
                    >
                      Restrict Scanning
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.checkCountOfScan?.message}
                  </span>
                </div>
              </div>

              <div className="p-grid p-col-10">
                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.showMfgDate}
                      name={"showMfgDate"}
                      inputId={obj.showMfgDate}
                      defaultValue={true}
                      ariaLabelledBy={obj.showMfgDate}
                      value={obj.showMfgDate}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.showMfgDate}
                      className="p-checkbox-label merginLeft15"
                    >
                      Show Mfg Month
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.showMfgDate?.message}
                  </span>
                </div>

                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.enableWarranty}
                      name={"enableWarranty"}
                      inputId={obj.enableWarranty}
                      defaultValue={true}
                      ariaLabelledBy={obj.enableWarranty}
                      value={obj.enableWarranty}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.enableWarranty}
                      className="p-checkbox-label merginLeft15"
                    >
                      Enable Warranty
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.enableWarranty?.message}
                  </span>
                </div>
              </div>

              <div className="p-grid p-col-10">
                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.showOffers}
                      name={"showOffers"}
                      inputId={obj.showOffers}
                      defaultValue={true}
                      ariaLabelledBy={obj.showOffers}
                      value={obj.showOffers}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.showOffers}
                      className="p-checkbox-label merginLeft15"
                    >
                      Show Offers
                    </label>
                    </div>
                    <span className="errorSpan merginLeft30">
                    {errors.showOffers?.message}
                  </span>
                  </div>
                  

                  <div className="p-col-5">
                    <div className="p-inputgroup">
                      <InputSwitch
                        checked={obj.staticQr}
                        name={"staticQr"}
                        inputId={obj.staticQr}
                        ariaLabelledBy={obj.staticQr}
                        value={obj.staticQr}
                        onChange={(event) => handleChange(event)}
                      />
                      <label
                        htmlFor={obj.staticQr}
                        className="p-checkbox-label merginLeft15"
                      >
                        Authentication Via QR Code
                      </label>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.staticQr?.message}
                    </span>
                  </div>

                  
                {/* </div> */}
              </div>


                {obj.mfgDate != undefined && (
                  <div className="p-col-5">
                    <div className="p-inputgroup">
                      <InputSwitch
                        checked={obj.showExpiryDate}
                        name={"showExpiryDate"}
                        inputId={obj.showExpiryDate}
                        defaultValue={true}
                        ariaLabelledBy={obj.showExpiryDate}
                        value={obj.showExpiryDate}
                        onChange={(event) => handleChange(event)}
                      />
                      <label
                        htmlFor={obj.showExpiryDate}
                        className="p-checkbox-label merginLeft15"
                      >
                        Show Expiry Month
                      </label>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.showExpiryDate?.message}
                    </span>
                  </div>
                )}
              {/* </div> */}

              <div className="p-grid p-col-10">
                <div className="p-col-5">
                  <div className="p-inputgroup">
                    <InputSwitch
                      checked={obj.enableLoyaltyPoints}
                      name={"enableLoyaltyPoints"}
                      inputId={obj.enableLoyaltyPoints}
                      defaultValue={true}
                      ariaLabelledBy={obj.enableLoyaltyPoints}
                      value={obj.enableLoyaltyPoints}
                      onChange={(event) => handleChange(event)}
                    />
                    <label
                      htmlFor={obj.enableLoyaltyPoints}
                      className="p-checkbox-label merginLeft15"
                    >
                      Enable Loyalty Points
                    </label>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.enableLoyaltyPoints?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="p-col-8">
              <label>Upload Product Image</label>
              <div className="p-col-8"></div>
              <div className="p-inputgroup">
                <Controller
                  className={errors.file ? "p-error" : ""}
                  disabled={disableFile}
                  rules={{}}
                  as={FileUpload}
                  control={control}
                  name="file"
                  onSelect={(e) => onFileSelect(e, "productImage")}
                  accept="image/*"
                  onRemove={(e) => onRemoveFile(e)}
                  maxFileSize={1000000}
                  customUpload={true}
                />
              </div>
              <span className="errorSpan merginLeft30">
                {errors.file?.message}
              </span>
            </div>

            <div className="p-col-8">
              <label>Upload Label Image</label>
              <div className="p-col-8"></div>
              <div className="p-inputgroup">
                <Controller
                  className={errors.file ? "p-error" : ""}
                  disabled={disableFile}
                  rules={{}}
                  as={FileUpload}
                  control={control}
                  name="file"
                  onSelect={(e) => onFileSelect(e, "labelImageUrl")}
                  accept="image/*"
                  onRemove={(e) => onRemoveFile(e)}
                  maxFileSize={1000000}
                  customUpload={true}
                />
              </div>
              <span className="errorSpan merginLeft30">
                {errors.file?.message}
              </span>
            </div>

            {obj.captureUserData && (
              <>
                <div className="p-col-8">
                  <label>Upload Image on Identification </label>
                  <div className="p-col-8"></div>
                  <div className="p-inputgroup">
                    <Controller
                      className={errors.file ? "p-error" : ""}
                      disabled={disableFile}
                      rules={{}}
                      as={FileUpload}
                      control={control}
                      name="file"
                      onSelect={(e) => onFileSelect(e, "imageOnIdentification")}
                      accept="image/*"
                      onRemove={(e) => onRemoveFile(e)}
                      maxFileSize={1000000}
                      customUpload={true}
                    />
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.file?.message}
                  </span>
                </div>

                <div className="p-col-8">
                  <label>Upload Image on OTP </label>
                  <div className="p-col-8"></div>
                  <div className="p-inputgroup">
                    <Controller
                      className={errors.file ? "p-error" : ""}
                      disabled={disableFile}
                      rules={{}}
                      as={FileUpload}
                      control={control}
                      name="file"
                      onSelect={(e) => onFileSelect(e, "imageOnOtp")}
                      accept="image/*"
                      onRemove={(e) => onRemoveFile(e)}
                      maxFileSize={1000000}
                      customUpload={true}
                    />
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.file?.message}
                  </span>
                </div>

                <div className="p-col-8">
                  <label>Upload Image on Customer Registration </label>
                  <div className="p-col-8"></div>
                  <div className="p-inputgroup">
                    <Controller
                      className={errors.file ? "p-error" : ""}
                      disabled={disableFile}
                      rules={{}}
                      as={FileUpload}
                      control={control}
                      name="file"
                      onSelect={(e) => onFileSelect(e, "imageOnRegistration")}
                      accept="image/*"
                      onRemove={(e) => onRemoveFile(e)}
                      maxFileSize={1000000}
                      customUpload={true}
                    />
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.file?.message}
                  </span>
                </div>

                <div className="p-col-8">
                  <label>Upload Image on Attachment </label>
                  <div className="p-col-8"></div>
                  <div className="p-inputgroup">
                    <Controller
                      className={errors.file ? "p-error" : ""}
                      disabled={disableFile}
                      rules={{}}
                      as={FileUpload}
                      control={control}
                      name="file"
                      onSelect={(e) => onFileSelect(e, "imageOnUploadInvoice")}
                      accept="image/*"
                      onRemove={(e) => onRemoveFile(e)}
                      maxFileSize={1000000}
                      customUpload={true}
                    />
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.file?.message}
                  </span>
                </div>
              </>
            )}

            {/* <div className="p-col-8">
                        <label >Upload Content</label>
                        <div className="p-col-8"></div>
                        <div className="p-inputgroup">
                            <Controller className={errors.contentFile ? "p-error" : ''} disabled={disableContentFile} rules={{}} as={FileUpload} control={control} name="file"
                                onSelect={e => onFileSelect(e, 'productContentUrl')} accept="video/*,.mp3,.gif,image/*" onRemove={(e) => onRemoveFile(e)} maxFileSize={5000000} customUpload={true} />
                        </div>
                        <span className="errorSpan merginLeft30">{errors.contentFile?.message}</span>
                    </div> */}

            <div className="p-col-12">
              <Button
                icon="pi pi-check"
                label="Save"
                disabled={disableSave}
                onClick={handleSubmit(save)}
              />
              {/* <Button icon="pi-md-clear" label="Clear" className="p-button-secondary" onClick={() => clear()} /> */}
              <Button
                icon="pi-md-cancel"
                label="Cancel"
                className="p-button-secondary"
                onClick={() => cancel()}
              />
            </div>
            <div className="p-col-8"></div>
          </div>
        </div>
        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(ProductAdd);
